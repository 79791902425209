import styled from 'styled-components'
import { Link } from 'gatsby'

export const Button = styled(Link)`
    background: ${({ primary }) => (primary ? '#EF6F6C' : '#71C4DE')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '10px 60px' : '10px')};
    color: white;
    font-size:  ${({ big }) => (big ? '20px' : '16px')};
    outline: none;
    border: 2px solid #EF6F6C;
    cursor: pointer;
    text-decoration: none;
    transitions: 0.3s !important;
    border-radius: 10px;
    margin-bottom: 1rem;
    

    &:hover {
        text-decoration-line: none;        
    }
`
