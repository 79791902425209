import React from 'react'
import styled from 'styled-components';


const Body = () => {
    return (
        <Container>
            <HeroPortfolio>
                <HeroHeader>Our Blog</HeroHeader>
            </HeroPortfolio>

        </Container>
    )
}

export default Body

const Container = styled.div`
    margin-top: 70px;
    width:100%;
`
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`
// const HeroP = styled.p`
//     font-size: clamp(1rem,2vw, 1.25rem)
// `

