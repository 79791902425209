import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import { Button } from '../components/Button'
import Body from '../components/blog/Body'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <SEO title="Blog - health information system kenya articles"
                    description="Articles on ways we could use health information systems to improve our health systems management"
                    keywords="health technology in Kenya, health system in Kenya, health information system in Kenya, digital health in Kenya, triple aim, quality of care, universal health coverage in Kenya,
                            health system management, kenya health information system, quality of health care, health technology, triple aim care health and cost, health data analytics, dashboard, data visualization,
                            health decision making, healthcare content marketing, digital marketing for healthcare, health analytics, artificial intelligence kenya, healthcare content writing "
                    img="https://www.tripleaimsoftware.com/static/d1196d7a95764ced50dd940c94e3f594/0a47e/ai.png"
                    siteUrl="https://www.tripleaimsoftware.com/blog/">
                </SEO>
                <Body />
                <p>
                    No blog posts found. Add markdown posts to "content/blog" (or the
                    directory you specified for the "gatsby-source-filesystem" plugin in
                    gatsby-config.js).
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Blog - articles on Digital Health and Health technologies"
                description="Articles on ways we could use health information systems to improve our health systems management"
                keywords="health technology in Kenya, health system in Kenya, health information system in Kenya, digital health in Kenya, triple aim, quality of care, universal health coverage in Kenya,
                health system management, kenya health information system, quality of health care, health technology, triple aim care health and cost, health data analytics, dashboard, data visualization,
                health decision making, healthcare content marketing, digital marketing for healthcare, health analytics, artificial intelligence kenya, healthcare content writing "
                image="https://www.tripleaimsoftware.com/static/d1196d7a95764ced50dd940c94e3f594/0a47e/ai.png"
                siteUrl="https://www.tripleaimsoftware.com/blog/"
                canonical="https://www.tripleaimsoftware.com/blog/"
            >
            </SEO>
            <Body />
            <ol style={{ listStyle: `none` }}>
                {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug
                    const tagsWords = post.frontmatter.tagsWords

                    return (
                        <Container>
                            <PostContainer data-sal="slide-up" data-sal-easing="ease" key={post.fields.slug}>
                                <article
                                    className="post-list-item"
                                    itemScope
                                    itemType="http://schema.org/Article"
                                >
                                    <header>
                                        <h2>
                                            <Link to={post.fields.slug} itemProp="url">
                                                <span itemProp="headline">{title}</span>
                                            </Link>
                                        </h2>
                                        <small>Posted By Dr. Ibrahim Okich on {post.frontmatter.date}</small>
                                        <Keywords style={{ listStyle: `none` }}>{tagsWords.map(tag => (
                                            <li key={tag}><button>{tag}</button></li>
                                        ))}
                                        </Keywords>
                                    </header>
                                    <section>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: post.frontmatter.description || post.excerpt,
                                            }}
                                            itemProp="description"
                                        />
                                    </section>
                                    <Link to={post.fields.slug}>
                                        <button css={`width: 50%; height: 50px; border-radius: 30px; background: #fff;border: 2px solid #71C4DE;`}>Continue Reading</button>
                                    </Link>
                                </article>
                            </PostContainer>

                        </Container>
                    )
                })}
            </ol>
            <SubscribeContainer>
                <ContainerP1>Want to understand more on the future of Health Technology? </ContainerP1>
                <ContainerP2>Get our free email series </ContainerP2>
                <form name="subscribe" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="subscribe" />
                    <FormWrap>
                        <label htmlFor="email">
                            <input type="email" placeholder="Enter Your Email" id="email" name="email" />
                        </label>
                        <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Free Newsletter</Button>
                    </FormWrap>
                </form>
            </SubscribeContainer>
        </Layout>
    )
}

export default BlogIndex

const Container = styled.div`
    width: 100%;
`

const PostContainer = styled.li`
    margin-top: 2rem;
    padding: 1rem 1rem;
    height: 100%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    

    @media screen and (min-width: 1000px) {
        max-width: 40%;
    }
`
const Keywords = styled.ol`
    display: flex;
    font-size: 0.625rem;
    flex-wrap: wrap;

    li {
        
        button {
            background: #E9F3FF;
            margin-right: 1rem;
            border: none;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

    }
`

const SubscribeContainer = styled.div`
    background: #71C4DE;
    margin-top: 2rem;
    margin-bottom: 1rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1000px) {
        max-width: 40%;
    }
`
const ContainerP1 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    padding: 2rem 1rem 0rem 1rem;
    
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
`
const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
   

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tagsWords
        }
      }
    }
  }
`
